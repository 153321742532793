export const DEFAULT_OPTIONS = {
    dotSize: (0.5 + 2.5) / 2,
    minWidth: 0.5,
    maxWidth: 2.5,
    throttle: 16,
    minDistance: 5,
    backgroundColor: 'rgba(0,0,0,0)',
    penColor: 'black',
    velocityFilterWeight: 0.7,
    onBegin: () => {},
    onEnd: () => {},
}

export const TRANSPARENT_PNG = {
    src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
    x: 0,
    y: 0,
}
