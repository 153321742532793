<template>
    <div>
        <div
            class="signature"
            :class="{ 'is-signing': hasStartedSigning }"
        >
            <div class="signature-guide">
                <img
                    src="@/assets/images/components/signaturePad/signHere.svg"
                    alt="Sign Here"
                >
                <hr>
            </div>
            <div
                v-if="hasStartedSigning"
                class="signature-undo"
                @click="clear"
            >
                <img
                    src="../../assets/images/components/signaturePad/undo.svg"
                    width="14px"
                    height="14px"
                    alt="Clear"
                >
            </div>

            <div
                v-if="!hasStartedSigning"
                class="signature-title"
            >
                <p>Sign here with your finger</p>
            </div>
            <vueSignaturePad
                class="signature-pad"
                id="signaturePad"
                ref="vueSignaturePad"
                @onSigned="onSigned"
                @onClear="onClear"
                :options="options"
            />
        </div>
    </div>
</template>

<script>
    import VueSignaturePad from '@/components/remoteNotarization/VueSignaturePad/VueSignaturePad'
    import { logger } from '@/utils/logger'
    const ON_SIGNED = 'on-signed'
    const ON_CLEAR = 'on-clear'
    const ON_CURVE = 'on-curve'
    export default {
        name: 'AvenSignaturePad',
        props: {
            name: String,
            hint: String,
        },
        components: {
            vueSignaturePad: VueSignaturePad,
        },
        data: function () {
            return {
                hasStartedSigning: false,
                triggerNewLayerEveryNCurves: 50,
                curvesInCurrentStroke: 0,
                options: {
                    penColor: '#000',
                    onBegin: this.onBegin,
                    onEnd: this.onEnd,
                    onCurve: this.onCurve,
                },
            }
        },
        methods: {
            clear: function () {
                this.$refs.vueSignaturePad.clear()
                // Reset the current curve counter, because we're starting from scratch
                this.curvesInCurrentStroke = 0
                // Shows the user the green 'sign here' intro message again
                this.hasStartedSigning = false
            },
            onBegin: function () {
                // Reset the current curve counter, because we're on a new stroke
                this.curvesInCurrentStroke = 0
                this.hasStartedSigning = true
            },
            onEnd: function () {
                this.$refs.vueSignaturePad.save()
            },
            onCurve: function (event, data) {
                this.curvesInCurrentStroke += 1
                if (this.curvesInCurrentStroke > this.triggerNewLayerEveryNCurves) {
                    logger.log(`Triggering manual signature save after ${this.triggerNewLayerEveryNCurves} curves`)
                    this.curvesInCurrentStroke = 0
                    this.onEnd()
                }
                this.$emit(ON_CURVE, { event, data })
            },
            onSigned: function (data) {
                // We don't want all of the pretty 'data:' image wrappers, just the SVG data itself
                const dataUrlSignature = data?.signature
                const base64SignatureData = dataUrlSignature?.split('base64,')?.[1]
                this.$emit(ON_SIGNED, base64SignatureData)
            },
            onClear: function () {
                this.$emit(ON_CLEAR)
            },
            off: function () {
                this.$refs.vueSignaturePad.off()
            },
            on: function () {
                this.$refs.vueSignaturePad.on()
            },
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    @import '../../styles/components/signaturePad';
</style>
