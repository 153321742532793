// Interface for point data structure used e.g. in SignaturePad#fromData method
export interface IBasicPoint {
    x: number
    y: number
    time: number
    pressure: number
}

export class Point implements IBasicPoint {
    public x: number
    public y: number
    public time: number
    public pressure: number

    constructor(x: number, y: number, time?: number, pressure?: number) {
        this.x = x
        this.y = y
        this.time = time || Date.now()
        this.pressure = pressure || 0
    }

    public clone(): Point {
        return new Point(this.x, this.y, this.time, this.pressure)
    }

    public distanceTo(start: IBasicPoint): number {
        return Math.sqrt(Math.pow(this.x - start.x, 2) + Math.pow(this.y - start.y, 2))
    }

    public equals(other: IBasicPoint): boolean {
        return this.x === other.x && this.y === other.y && this.time === other.time && this.pressure === other.pressure
    }

    public velocityFrom(start: IBasicPoint): number {
        return this.time !== start.time ? this.distanceTo(start) / (this.time - start.time) : 0
    }

    public scaleByFactor(factor: number, scaleX: boolean = true, scaleY: boolean = true): void {
        if (scaleX) this.x *= factor
        if (scaleY) this.y *= factor
    }
}
