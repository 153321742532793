<template>
    <div class="layout">
        <div class="applicant-remote-notarization-layout-container">
            <loading-indicator
                v-if="loading"
                class="applicant-remote-notarization-layout-container loader"
                :title="loadingTitle"
                :sub-title="loadingSubTitle"
            />
            <div
                v-show="!loading"
                class="applicant-remote-notarization-layout-content"
            >
                <div
                    v-if="error"
                    class="alert alert-info"
                    role="alert"
                >
                    <span v-html="errorText" />
                </div>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingIndicator from '@/components/LoadingIndicator'

    export default {
        name: 'ApplicantRemoteNotarizationLayout',
        props: {
            loading: { type: Boolean },
            loadingTitle: { type: String },
            loadingSubTitle: { type: String },
            error: { type: Boolean },
            errorText: { type: String },
        },
        components: {
            'loading-indicator': LoadingIndicator,
        },
        beforeCreate: function () {
            document.body?.classList?.remove('bg-gray-100')
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/layouts/remoteNotarization/applicant';
</style>
