<template>
    <!--
        TODO: how can we provide access for D/deaf applicants?
    -->
    <applicant-remote-notarization-layout
        :error="!!errorText"
        :error-text="errorText"
        :loading="!notaryRemoteParticipant"
        :loading-title="errorText ? 'Error Joining Session' : $t('remoteNotarization.enterQueue.title')"
        :loading-sub-title="errorText ? errorText : loadingSubTitle"
    >
        <div
            id="audio"
            ref="notaryAudio"
        />

        <div
            id="topBar"
            v-show="
                [
                    SessionState.viewingFaceVideos,
                    SessionState.performingFrontIdVerification,
                    SessionState.performingBackIdVerification,
                    SessionState.takeBackIDPicture,
                    SessionState.takeFrontIDPicture,
                    SessionState.gatherPlotterSignature,
                ].includes(sessionState)
            "
        >
            <img
                src="@/assets/images/global/aven.svg"
                alt="Aven logo"
                class="logo"
                width="71"
            >
            <span
                id="timer"
                v-if="timerText"
                v-show="false"
            >{{ timerText }}</span>
        </div>

        <div
            v-show="
                [
                    SessionState.viewingFaceVideos,
                    SessionState.performingFrontIdVerification,
                    SessionState.performingBackIdVerification,
                    SessionState.takeBackIDPicture,
                    SessionState.takeFrontIDPicture,
                    SessionState.gatherPlotterSignature,
                ].includes(sessionState)
            "
            class="face-videos"
        >
            <div
                id="largeVideo"
                ref="largeBodyVideo"
                :class="sessionState === SessionState.performingFrontIdVerification ? 'flip-me' : ''"
            >
                <div
                    id="largeVideoOverlay"
                    v-if="largeBodyVideoLoadingDelayed"
                >
                    <span class="spinner-border" />
                </div>
            </div>
            <div v-if="sessionState === SessionState.viewingFaceVideos">
                <p class="fw-bold text-center mt-2 mb-0">
                    {{ notaryName }}
                </p>
                <p class="section-header text-center text-muted">
                    Notary
                </p>
            </div>
            <div v-if="[SessionState.performingFrontIdVerification, SessionState.performingBackIdVerification, SessionState.takeFrontIDPicture, SessionState.takeBackIDPicture].includes(sessionState)">
                <p class="fw-bold text-center mt-2 mb-0">
                    Please Hold Up the {{ [SessionState.performingFrontIdVerification, SessionState.takeFrontIDPicture].includes(sessionState) ? 'Front' : 'Back' }} of your ID
                </p>
            </div>
            <div
                v-if="[SessionState.gatherPlotterSignature].includes(sessionState)"
                class="mt-2 justify-content-center align-items-center text-center"
            >
                <p class="fw-bold text-center mt-2 mb-0">
                    Larry
                </p>
                <p class="section-header text-center text-muted">
                    Robot
                </p>
                <signature-pad
                    ref="signaturePad"
                    @on-signed="submitSignatureData"
                    class="mt-5"
                    v-show="sessionState === SessionState.gatherPlotterSignature"
                />
            </div>
            <div
                id="smallVideo"
                ref="smallBodyVideo"
                v-show="![SessionState.gatherPlotterSignature].includes(sessionState)"
            >
                <div
                    id="smallVideoOverlay"
                    v-if="smallBodyVideoLoadingDelayed"
                >
                    <span class="spinner-border spinner-border-sm" />
                </div>
            </div>
        </div>

        <div
            id="documentMetaContainer"
            v-show="[SessionState.viewingDocuSign, SessionState.refreshDocuSign, SessionState.viewingDeedOfTrust].includes(sessionState)"
        >
            <div id="titleBar">
                <p
                    id="pageTitle"
                    class="section-header fw-bold text-center"
                >
                    {{ sessionState === SessionState.viewingDeedOfTrust ? 'VIEW DOCUMENTS' : 'SIGN DOCUMENTS' }}
                </p>
                <div id="titleBarVideoBox">
                    <div
                        id="notaryTitleVideo"
                        ref="notaryTitleVideo"
                    />
                    <div
                        id="applicantTitleVideo"
                        ref="applicantTitleVideo"
                    />
                </div>
            </div>

            <docusign
                ref="docuSign"
                v-if="[SessionState.viewingDocuSign, SessionState.refreshDocuSign].includes(sessionState)"
                :agent="SessionAgent.applicant"
            />
            <deed-of-trust
                v-if="sessionState === SessionState.viewingDeedOfTrust"
                ref="deedOfTrust"
                :agent="SessionAgent.applicant"
            />
        </div>

        <!--        <div-->
        <!--            class="collapse pt-2 pb-8"-->
        <!--            v-show="false"-->
        <!--        >-->
        <!--            <span class="text-muted">Applicant Video Device:</span>-->
        <!--            <select v-model="selectedVideoDeviceId">-->
        <!--                <option-->
        <!--                    v-for="(videoDevice, index) in availableVideoDevices"-->
        <!--                    :value="videoDevice.deviceId"-->
        <!--                    :key="'applicantVideo' + videoDevice.deviceId"-->
        <!--                >-->
        <!--                    {{ videoDevice.label || 'Camera ' + index }}-->
        <!--                </option>-->
        <!--            </select>-->
        <!--            <span class="text-muted">Applicant Audio Device:</span>-->
        <!--            <select v-model="selectedAudioDeviceId">-->
        <!--                <option-->
        <!--                    v-for="(audioDevice, index) in availableAudioDevices"-->
        <!--                    :value="audioDevice.deviceId"-->
        <!--                    :key="'applicantAudio' + audioDevice.deviceId"-->
        <!--                >-->
        <!--                    {{ audioDevice.label || 'Audio ' + index }}-->
        <!--                </option>-->
        <!--            </select>-->
        <!--        </div>-->
    </applicant-remote-notarization-layout>
</template>

<script>
    import ApplicantRemoteNotarizationLayout from '@/layouts/remoteNotarization/Applicant'
    import { logger } from '@/utils/logger'
    import {
        enterApplicantNotarySession,
        getApplicantStatus,
        getNotaryInfo,
        processCustomerIDDocument,
        SessionType,
        setApplicantSessionState,
        submitPlotterSignature,
    } from '@/services/remoteNotarizationApi'
    import { attachTrack, MAX_TRACK_PUBLISH_TIME_MSEC, NOTARY_VIDEO_PREFIX, PLOTTER_VIDEO_PREFIX, SessionAgent, SessionState } from '@/utils/remoteNotarization'
    import { getNextRoute } from '@/flow/flowController'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import DocuSign from '@/components/remoteNotarization/DocuSign'
    import router from '@/routes/router'
    import { connect, createLocalTracks } from 'twilio-video'
    import assert from 'assert'
    import { isEqual } from 'lodash'
    import { i18n } from '@/utils/i18n'
    import DeedOfTrust from '@/components/remoteNotarization/DeedOfTrust'
    import AvenSignaturePad from '@/components/remoteNotarization/AvenSignaturePad'
    import { awaitWithTimeout } from '@/utils/asyncTools'
    import { runWithRetryLogic } from '@/utils/http-client'
    import { generatePifInvitesForCardholdersOnLoanApplication } from '@/services/payItForward'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { MiscThanksReasons } from '@/utils/thanksPageHelpers'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { fireAndForget } from '@/utils/fireAndForget'
    import { inspect } from '@/utils/inspect'
    import { DateTime } from 'luxon'

    export default {
        components: {
            docusign: DocuSign,
            'applicant-remote-notarization-layout': ApplicantRemoteNotarizationLayout,
            // RIN Only Modes
            'signature-pad': AvenSignaturePad,
            'deed-of-trust': DeedOfTrust,
        },
        data() {
            return {
                // Passing various enums / funcs to template code
                SessionAgent,
                SessionState,

                // Data about us
                applicantStatus: null,
                applicantNotaryAssignmentId: null,

                // Our twilio config
                twilioRoom: null,
                twilioAccessToken: null,
                localVideoTrack: null,
                localVideoTrackElem: null,
                localAudioTrack: null,
                localVideoTrackAttachment: null,

                // The notary twilio tracks
                notaryRemoteParticipant: null,
                notaryRemoteVideoTrack: null,
                notaryRemoteVideoTrackElem: null,
                notaryRemoteAudioTrack: null,
                notaryRemoteAudioTrackElem: null,
                notaryRemoteVideoTrackAttachment: null,

                // The plotter twilio tracks (set only for RIN modes)
                plotterRemoteVideoTrack: null,
                plotterRemoteVideoTrackElem: null,
                plotterRemoteVideoTrackAttachment: null,

                // Selecting AV devices locally
                selectedVideoDeviceId: null,
                selectedAudioDeviceId: null,
                availableVideoDevices: null,
                availableAudioDevices: null,

                // Polling for updated data about us
                pollApplicantStatusIntervalId: null,
                pollApplicantStatusIntervalMsec: Number(appSessionStorage.getItem(localStorageKey.pollInterval) ?? 1000),

                // Updating minutes remaining timer
                pollTimeRemainingIntervalId: null,
                // this is a luxon duration object
                timeRemainingDuration: null,
                // Take the expected session duration and divide it by this number (this is what we show to the user
                // Ex: 30min / sessionScheduledDurationAdjustmentFactor of 2 -> 15min
                sessionScheduledDurationAdjustmentFactor: 1,

                // Info about notary
                notaryInfo: null,

                // ID Verification
                frontB64Image: null,

                // UI Related things
                errorText: '',
                largeBodyVideoLoading: true,
                smallBodyVideoLoading: true,
                // These get set by a watch, don't modify manually
                largeBodyVideoLoadingDelayed: true,
                smallBodyVideoLoadingDelayed: true,
                // Necessary b/c the video element doesn't provide feedback when it is 'mounted' on the DOM
                loadingDelayMsec: 3000,
            }
        },
        computed: {
            // We need these b/c optional accessors are not supported in templates in vuejs v2
            // https://github.com/vuejs/vue/issues/11088
            sessionState() {
                return this.applicantStatus?.sessionState
            },
            sessionType() {
                return this.applicantStatus?.sessionType
            },
            sessionStartTime() {
                return this.applicantStatus?.sessionStartTime ? new Date(this.applicantStatus.sessionStartTime) : null
            },
            sessionScheduledDurationMin() {
                return this.applicantStatus?.sessionScheduledDurationMin
            },
            notaryName() {
                return this.notaryInfo?.name || 'Notary'
            },
            loadingSubTitle() {
                if (!this.notaryRemoteParticipant) {
                    return i18n.t('remoteNotarization.enterQueue.subTitle', { notaryName: this.notaryName.split(' ')[0] })
                }
                return ''
            },
            // Needs to be created at runtime here in order to use 'this.$refs'
            videoAttachment() {
                return {
                    smallBodyVideo: this.$refs.smallBodyVideo,
                    largeBodyVideo: this.$refs.largeBodyVideo,
                    applicantTitleVideo: this.$refs.applicantTitleVideo,
                    notaryTitleVideo: this.$refs.notaryTitleVideo,
                }
            },
            isReadyToStartTwilioRoom() {
                // !this.twilioRoom is necessary to prevent us setting the twilioRoom more than once
                const value = !!this.localVideoTrack && !!this.localAudioTrack && !!this.twilioAccessToken && !this.twilioRoom

                logger.log(`Calculating isReadyToStartTwilioRoom: ${value} |
                this.localVideoTrack: ${!!this.localVideoTrack}
                && this.localAudioTrack: ${!!this.localAudioTrack}
                && this.twilioAccessToken: ${!!this.twilioAccessToken}
                && !this.twilioRoom: ${!this.twilioRoom}`)

                return value
            },
            timerText: function () {
                if (!this.timeRemainingDuration) return null

                if (this.timeRemainingDuration.milliseconds <= 0) {
                    return '0:00 Mins'
                }

                return this.timeRemainingDuration.toFormat('mm:ss') + ' Mins'
            },
        },
        watch: {
            // These will replay all events that occur to their source vals, just pushed back 5 sec for 'false' values
            // Works similarly to an SR latch to let us set quickly, and delay 'unsetting' the loading animation
            largeBodyVideoLoading(newVal) {
                if (newVal) {
                    this.largeBodyVideoLoadingDelayed = true
                } else {
                    setTimeout(() => (this.largeBodyVideoLoadingDelayed = newVal), this.loadingDelayMsec)
                }
            },
            smallBodyVideoLoading(newVal) {
                if (newVal) {
                    this.smallBodyVideoLoadingDelayed = true
                } else {
                    setTimeout(() => (this.smallBodyVideoLoadingDelayed = newVal), this.loadingDelayMsec)
                }
            },
            applicantStatus: function (newApplicantStatus, oldApplicantStatus) {
                if (isEqual(newApplicantStatus, oldApplicantStatus)) {
                    return
                }

                logger.info(`Updated notaryStatus from ${JSON.stringify(oldApplicantStatus)} to ${JSON.stringify(newApplicantStatus)}`)
            },
            sessionStartTime: function (newVal, oldVal) {
                if (isEqual(newVal, oldVal)) {
                    return
                }

                if (newVal) {
                    this.pollTimeRemaining()
                    logger.info(`Updated sessionStartTime from ${oldVal} to ${newVal}, starting poller for time remaining`)
                } else {
                    window.clearInterval(this.pollTimeRemainingIntervalId)
                    this.timeRemainingDuration = null
                    logger.info(`Updated sessionStartTime from ${oldVal} to ${newVal}, clearing poller for time remaining`)
                }
            },
            sessionState: async function (newState, oldState) {
                logger.info(`Updated sessionState from ${oldState} to ${newState}`)

                switch (newState) {
                    case SessionState.ejectApplicantFromCall:
                        logger.info('Applicant ejected from the call, redirecting to success page')
                        await setApplicantSessionState(SessionState.viewingFaceVideos)
                        // We have to manually call this in order for our watched handlers to trigger
                        this.unmounted()
                        // Don't allow user to go backwards, also force triggers destruction of notary page
                        return await this.$router.replace(getNextRoute(this.$router))
                    case SessionState.sessionCompleteSuccess:
                        logger.info('Successful session completion. Redirecting.')
                        // We have to manually call this in order for our watched handlers to trigger
                        this.unmounted()
                        // Don't allow user to go backwards, also force triggers destruction of notary page
                        return await this.$router.replace(getNextRoute(this.$router))
                    case SessionState.sessionCompleteFailure:
                    case SessionState.sessionPartialFailure:
                        logger.info('Unsuccessful session completion. Redirecting.')
                        // We have to manually call this in order for our watched handlers to trigger
                        this.unmounted()
                        // Don't allow user to go backwards, also force triggers destruction of notary page
                        return await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: MiscThanksReasons.notaryRejection } })
                    case SessionState.takeFrontIDPicture:
                        await this.tryTakeFrontIDPicture()
                        logger.log('Probably took front ID picture already, delaying a few second before updating UI')
                        await new Promise((resolve) => setTimeout(resolve, 3000))
                        logger.log('Took front ID picture, updating session state to performingBackIdVerification')
                        await setApplicantSessionState(SessionState.performingBackIdVerification)
                        break
                    case SessionState.takeBackIDPicture:
                        await this.tryTakeBackIDPicture()
                        logger.log('Probably took back ID picture already, delaying a few second before updating UI')
                        await new Promise((resolve) => setTimeout(resolve, 3000))
                        logger.log('Took back ID picture, updating session state back to viewingFaceVideos')
                        await setApplicantSessionState(SessionState.viewingFaceVideos)
                        break
                    case SessionState.refreshDocuSign:
                        logger.log('Refreshed docusign, updating session state back to viewingDocuSign')
                        await setApplicantSessionState(SessionState.viewingDocuSign)
                        break
                    case SessionState.performingFrontIdVerification:
                        logger.log('Swapping video attachments between applicant and notary for ID verification')
                        this.plotterRemoteVideoTrackAttachment = null
                        this.localVideoTrackAttachment = this.videoAttachment.largeBodyVideo
                        this.notaryRemoteVideoTrackAttachment = this.videoAttachment.smallBodyVideo
                        break
                    case SessionState.performingBackIdVerification:
                        if (!this.frontB64Image) {
                            logger.warn('Not sure how we got to performingBackIdVerification without this.frontB64Image, reverting to performingFrontIdVerification')
                            await setApplicantSessionState(SessionState.performingFrontIdVerification)
                        }
                        // Otherwise, nothing special to do
                        break
                    case SessionState.gatherPlotterSignature:
                        assert(this.sessionType === SessionType.RIN, 'Getting RIN modes in a RON session!')
                        logger.log('Resetting video device and attachments for gatherPlotterSignature / plotterSigning')
                        // These are optional b/c signaturePad may not be available yet if the page just reloaded
                        // It doesn't matter however, b/c the signature pad will be cleared and on by default
                        this.$refs.signaturePad?.clear()
                        this.$refs.signaturePad?.on()
                        this.localVideoTrackAttachment = null
                        this.notaryRemoteVideoTrackAttachment = null
                        this.plotterRemoteVideoTrackAttachment = this.videoAttachment.largeBodyVideo
                        break
                    case SessionState.viewingFaceVideos:
                        logger.log('Resetting video device and attachments for viewingFaceVideos')
                        this.plotterRemoteVideoTrackAttachment = null
                        this.localVideoTrackAttachment = this.videoAttachment.smallBodyVideo
                        this.notaryRemoteVideoTrackAttachment = this.videoAttachment.largeBodyVideo
                        break
                    case SessionState.viewingDeedOfTrust:
                        assert(this.sessionType === SessionType.RIN, 'Getting RIN modes in a RON session!')
                        this.plotterRemoteVideoTrackAttachment = null
                        this.localVideoTrackAttachment = this.videoAttachment.applicantTitleVideo
                        this.notaryRemoteVideoTrackAttachment = this.videoAttachment.notaryTitleVideo
                        break
                    case SessionState.viewingDocuSign:
                        this.plotterRemoteVideoTrackAttachment = null
                        this.localVideoTrackAttachment = this.videoAttachment.applicantTitleVideo
                        this.notaryRemoteVideoTrackAttachment = this.videoAttachment.notaryTitleVideo
                        break
                    default:
                        logger.error(`Received an unexpected session state: ${newState}`)
                }
                this.$logEvent('event_applicant_switching_notary_session_state', { from: this.sessionState, to: newState })
                logger.info(`Successfully transitioned to session state: ${newState}`)

                if (!oldState && !!newState) {
                    logger.info(`Triggering onApplicantStatusSet because sessionState is transitioning from ${oldState} -> ${newState}`)
                    // this should only occur once, when a user is loading the page for the first time
                    // if this gets called more than once (probably) nothing will happen but no reason to add noise
                    await this.onApplicantStatusSetFirstTime()
                }
            },
            selectedVideoDeviceId: async function (newVideoDeviceId, oldVideoDeviceId) {
                logger.info(`Updated selectedVideoDeviceId from ${oldVideoDeviceId} to ${newVideoDeviceId}`)

                if (!newVideoDeviceId) return

                try {
                    const newLocalVideoTracksOrException = await createLocalTracks({
                        video: {
                            name: `applicantVideo-${newVideoDeviceId}`,
                            deviceId: newVideoDeviceId,
                            width: {
                                min: 640,
                                max: 1920,
                                ideal: 1920,
                            },
                            height: {
                                min: 480,
                                max: 1080,
                                ideal: 1080,
                            },
                        },
                    })

                    this.localVideoTrack = newLocalVideoTracksOrException[0]

                    logger.info(`this.localVideoTrack updated!`)
                } catch (e) {
                    logger.error(`Notarization Session Problem: Error creating new localVideoTrack!`, null /* event */, e)
                    this.errorText = 'We failed to get access to your video camera. Please try visiting on another device.'
                }
            },
            selectedAudioDeviceId: async function (newAudioDeviceId, oldAudioDeviceId) {
                logger.info(`Updated selectedAudioDeviceId from ${oldAudioDeviceId} to ${newAudioDeviceId}`)

                if (!newAudioDeviceId) return

                try {
                    const newLocalAudioTracksOrException = await createLocalTracks({
                        audio: {
                            name: `applicantAudio-${newAudioDeviceId}`,
                            deviceId: newAudioDeviceId,
                        },
                    })

                    this.localAudioTrack = newLocalAudioTracksOrException[0]

                    logger.info(`this.localAudioTrack updated!`)
                } catch (e) {
                    logger.error(`Notarization Session Problem: Error creating new localAudioTrack!`, null /* event*/, e)
                    this.errorText = 'We failed to get access to your microphone. Please try visiting on another device.'
                }
            },
            localVideoTrack: async function (newVideoTrack, oldVideoTrack) {
                logger.info(`Updated localVideoTrack from ${oldVideoTrack} to ${newVideoTrack}`)

                if (oldVideoTrack) {
                    logger.log(`Attempting to stop ${oldVideoTrack} before acting on ${newVideoTrack}`)
                    oldVideoTrack.stop()
                }

                if (this.localVideoTrackAttachment && newVideoTrack) {
                    logger.log(`Attaching newVideoTrack to DOM @ ${this.localVideoTrackAttachment?.id}`)

                    // This is what allows our lovely applicant gets to see the new track
                    this.localVideoTrackElem = newVideoTrack.attach()
                } else {
                    logger.log(`localVideoTrackAttachment and/or newVideoTrack is unset, skipping mounting of ${newVideoTrack}`)
                }

                // If twilioRoom and/or newVideoTrack is null, no need to publish / un-publish tracks and the sort
                if (!this.twilioRoom || !newVideoTrack) {
                    logger.log('this.twilioRoom and/or newVideoTrack is unset, skipping handler')
                    return
                }

                // Don't try to un-publish null values
                if (oldVideoTrack) {
                    logger.info(`Room is active, un-publishing ${oldVideoTrack}`)
                    this.twilioRoom.localParticipant.unpublishTrack(oldVideoTrack)
                }

                // Don't try to publish null values
                if (newVideoTrack) {
                    logger.info(`Room is active, publishing ${newVideoTrack}`)
                    // https://github.com/twilio/twilio-video.js/issues/963#issuecomment-795819739
                    await awaitWithTimeout(this.twilioRoom.localParticipant.publishTrack(newVideoTrack), MAX_TRACK_PUBLISH_TIME_MSEC)
                }
            },
            localVideoTrackElem: async function (newLocalVideoTrackElem, oldLocalVideoTrackElem) {
                logger.info(`Updated localVideoTrackElem from ${oldLocalVideoTrackElem?.id} to ${newLocalVideoTrackElem?.id}`)

                // Set the loading indicators as necessary
                if (this.localVideoTrackAttachment === this.videoAttachment.largeBodyVideo) {
                    this.largeBodyVideoLoading = true
                } else if (this.localVideoTrackAttachment === this.videoAttachment.smallBodyVideo) {
                    this.smallBodyVideoLoading = true
                }

                // Remove the old Elem from the DOM always, if it exists
                oldLocalVideoTrackElem?.remove()

                if (!newLocalVideoTrackElem) {
                    return
                }

                assert(this.localVideoTrackAttachment, "You can't set the localVideoTrackElem if your localVideoTrackAttachment is null!")

                // Add the new element to the correct spot in the DOM
                this.localVideoTrackAttachment.appendChild(newLocalVideoTrackElem)

                // Set the loaders to false. They are time delayed internally to give the videos a chance to spin up
                if (this.localVideoTrackAttachment === this.videoAttachment.largeBodyVideo) {
                    this.largeBodyVideoLoading = false
                } else if (this.localVideoTrackAttachment === this.videoAttachment.smallBodyVideo) {
                    this.smallBodyVideoLoading = false
                }
            },
            localVideoTrackAttachment: async function (newVideoTrackAttachment, priorVideoTrackAttachment) {
                logger.info(`Updated localVideoTrackAttachment from ${priorVideoTrackAttachment?.id} to ${newVideoTrackAttachment?.id}`)

                if (this.localVideoTrackElem) {
                    logger.log(`Unmounting localVideoTrackElem`)
                    // This is what allows our lovely applicant gets to see the new track
                    this.localVideoTrackElem = null
                }

                if (this.localVideoTrack && newVideoTrackAttachment) {
                    logger.log(`Remounting localVideoTrack to DOM @ ${newVideoTrackAttachment?.id}`)
                    this.localVideoTrackElem = this.localVideoTrack.attach()
                } else {
                    logger.log(`localVideoTrack or localVideoTrackAttachment is unset, skipping remounting`)
                }
            },
            localAudioTrack: async function (newAudioTrack, oldAudioTrack) {
                logger.info(`Updated localAudioTrack from ${oldAudioTrack} to ${newAudioTrack}`)

                if (oldAudioTrack) {
                    logger.log(`Attempting to stop ${oldAudioTrack} before acting on ${newAudioTrack}`)
                    oldAudioTrack.stop()
                }

                if (!this.notaryRemoteAudioTrack && newAudioTrack) {
                    // Note: We pause our local audio if the notary remote audio is not currently connected
                    // We do this b/c the notary remote audio must start playing /before/ we enable our microphone track
                    // Otherwise some iPhone versions will push the notary remote audio to the earpiece instead of the loudspeaker
                    // See: https://gitlab.com/jam-systems/jam/-/issues/19
                    logger.log('notaryRemoteAudioTrack appears to be unset, going to pause our localAudioTrack')
                    newAudioTrack.disable()
                } else {
                    logger.log('notaryRemoteAudioTrack appears to be set, leaving localAudioTrack enabled')
                }

                // If twilioRoom and/or newAudioTrack is null, no need to publish / un-publish tracks and the sort
                if (!this.twilioRoom || !newAudioTrack) {
                    logger.log('this.twilioRoom and/or newAudioTrack is unset, skipping handler')
                    return
                }

                // Don't try to un-publish null values
                if (oldAudioTrack) {
                    logger.info(`Room is active, un-publishing ${oldAudioTrack}`)
                    this.twilioRoom.localParticipant.unpublishTrack(oldAudioTrack)
                }

                // Don't try to publish null values
                if (newAudioTrack) {
                    logger.info(`Room is active, publishing ${newAudioTrack}`)
                    // https://github.com/twilio/twilio-video.js/issues/963#issuecomment-795819739
                    await awaitWithTimeout(this.twilioRoom.localParticipant.publishTrack(newAudioTrack), MAX_TRACK_PUBLISH_TIME_MSEC)
                }
            },
            twilioRoom: async function (newTwilioRoom, oldTwilioRoom) {
                logger.info(`Updated twilioRoom from ${oldTwilioRoom} to ${newTwilioRoom}`)

                if (oldTwilioRoom) {
                    logger.log('Disconnect from old twilio room before making changes')
                    clearInterval(this.networkStatsLogIntervalId)
                    oldTwilioRoom.disconnect()
                }

                // If newTwilioRoom is null, no need to attach handlers and the sort
                if (!newTwilioRoom) {
                    logger.log('Clearing existing credentials for null twilio room to prevent accidental reconnect')
                    this.twilioAccessToken = null
                    return
                }

                logger.log('Attaching handlers for twilioRoom')

                // We need to attach tracks for the other side if the notary is in the twilioRoom before we are
                // Indeed, we need this fancy syntax to convert from a <String: RemoteParticipant> map to just the RP
                const existingParticipant = [...newTwilioRoom.participants.values()][0]
                if (existingParticipant) {
                    this.notaryRemoteParticipant = existingParticipant
                    logger.info(`A remote participant is already connected: ${existingParticipant.identity}.`)
                } else {
                    logger.info('Looks like there are no existing remote participants')
                }

                // We also want to attach listeners in case the notary is not yet here
                newTwilioRoom.on('participantConnected', async (participant) => {
                    this.notaryRemoteParticipant = participant
                    logger.info(`The notary (new remote participant) connected: ${participant.identity}.`)
                })

                // In case the notary disconnects, log it and remove their tracks
                newTwilioRoom.on('participantDisconnected', async (participant) => {
                    this.notaryRemoteParticipant = null
                    logger.info(`The notary (existing remote participant) disconnected: ${participant.identity}.`)
                })

                // In case we disconnect from the twilioRoom, log it
                // It's possible that the notary will destroy our twilio session before we've left the page
                newTwilioRoom.on('disconnected', (room, reason) => {
                    logger.info(`Twilio room has disconnected: ${reason}`)
                    this.twilioRoom = null
                })
            },
            notaryRemoteParticipant: async function (newNotaryRemoteParticipant, oldNotaryRemoteParticipant) {
                logger.info(`Updated notaryRemoteParticipant from ${oldNotaryRemoteParticipant} to ${newNotaryRemoteParticipant}`)

                // Note we explicitly do not remove existing tracks if newNotaryRemoteParticipant is null
                // We're relying on the handlers below to properly clean up the tracks
                if (!newNotaryRemoteParticipant) {
                    return
                }

                logger.info(`Attaching participant handlers for participant ${newNotaryRemoteParticipant.identity}`)

                // subscribed === they have made the track available for us
                newNotaryRemoteParticipant.on('trackSubscribed', (track) => {
                    logger.info(`notaryRemoteParticipant published ${track}`)

                    logger.info(`Adding notary track of kind ${track.kind}.`)

                    if (track.kind === 'audio') {
                        this.notaryRemoteAudioTrack = track
                    } else if (track.kind === 'video' && track.name.startsWith(NOTARY_VIDEO_PREFIX)) {
                        this.notaryRemoteVideoTrack = track
                    } else if (track.kind === 'video' && track.name.startsWith(PLOTTER_VIDEO_PREFIX)) {
                        this.plotterRemoteVideoTrack = track
                    } else {
                        logger.error(`Unknown notaryRemoteParticipant track added! Unable to mount ${track.name}`)
                    }
                })

                // un-subscribed === they have removed the track from the room
                // gets called on remoteParticipant disconnect as well
                newNotaryRemoteParticipant.on('trackUnsubscribed', (track) => {
                    logger.info(`notaryRemoteParticipant unpublished ${track}`)

                    if (track === this.notaryRemoteVideoTrack) {
                        this.notaryRemoteVideoTrack = null
                    } else if (track === this.plotterRemoteVideoTrack) {
                        this.plotterRemoteVideoTrack = null
                    } else if (track === this.notaryRemoteAudioTrack) {
                        this.notaryRemoteAudioTrack = null
                    } else {
                        logger.error('Unknown track reference! Unable to remove')
                    }
                })
            },
            notaryRemoteAudioTrack: async function (newRemoteAudioTrack, oldRemoteAudioTrack) {
                logger.info(`Updated notaryRemoteAudioTrack from ${oldRemoteAudioTrack} to ${newRemoteAudioTrack}`)

                if (this.notaryRemoteAudioTrackElem) {
                    logger.log(`Unmounting notaryRemoteAudioTrack`)
                    this.notaryRemoteAudioTrackElem.remove()
                }

                if (!newRemoteAudioTrack) {
                    return
                }

                logger.log(`Setting new notaryRemoteAudioTrack`)
                this.notaryRemoteAudioTrackElem = attachTrack(newRemoteAudioTrack, this.$refs.notaryAudio)

                // Note: we must re-enable our microphone only /after/ we have attached the remote audio track
                // Otherwise some iPhone versions will push the audio output to the earpiece speaker instead of the loudspeaker
                // See: https://gitlab.com/jam-systems/jam/-/issues/19
                logger.log('Re-enabling local audio track (if it was disabled) now that we have a new notaryRemoteAudioTrack')
                this.localAudioTrack.enable()
            },
            notaryRemoteVideoTrack: async function (newRemoteVideoTrack, oldRemoteVideoTrack) {
                logger.info(`Updated notaryRemoteVideoTrack from ${oldRemoteVideoTrack} to ${newRemoteVideoTrack}`)

                if (this.notaryRemoteVideoTrackAttachment === this.videoAttachment.largeBodyVideo) {
                    this.largeBodyVideoLoading = true
                } else if (this.notaryRemoteVideoTrackAttachment === this.videoAttachment.smallBodyVideo) {
                    this.smallBodyVideoLoading = true
                }

                if (this.notaryRemoteVideoTrackElem) {
                    logger.log(`Unmounting notaryRemoteVideoTrack`)
                    this.notaryRemoteVideoTrackElem = null
                }

                if (!newRemoteVideoTrack) {
                    return
                }

                if (this.notaryRemoteVideoTrackAttachment) {
                    // This lets our notary see the wonderful face of our customer
                    logger.log(`Setting new notaryRemoteVideoTrack, attaching to DOM @ ${this.notaryRemoteVideoTrackAttachment.id}`)
                    this.notaryRemoteVideoTrackElem = newRemoteVideoTrack.attach()
                } else {
                    logger.log(`notaryRemoteVideoTrackAttachment is unset, skipping mounting of ${newRemoteVideoTrack}`)
                }
            },
            plotterRemoteVideoTrack: async function (newPlotterRemoteVideoTrack, oldPlotterRemoteVideoTrack) {
                logger.info(`Updated plotterRemoteVideoTrack from ${oldPlotterRemoteVideoTrack} to ${newPlotterRemoteVideoTrack}`)

                if (this.plotterRemoteVideoTrackAttachment === this.videoAttachment.largeBodyVideo) {
                    this.largeBodyVideoLoading = true
                } else if (this.plotterRemoteVideoTrackAttachment === this.videoAttachment.smallBodyVideo) {
                    this.smallBodyVideoLoading = true
                }

                if (this.plotterRemoteVideoTrackElem) {
                    logger.log(`Unmounting plotterRemoteVideoTrack`)
                    this.plotterRemoteVideoTrackElem = null
                }

                if (!newPlotterRemoteVideoTrack) {
                    return
                }

                if (this.plotterRemoteVideoTrackAttachment) {
                    // This lets our notary see the wonderful face of our customer
                    logger.log(`Setting new plotterRemoteVideoTrack, attaching to DOM @ ${this.plotterRemoteVideoTrackAttachment.id}`)
                    this.plotterRemoteVideoTrackElem = newPlotterRemoteVideoTrack.attach()
                } else {
                    logger.log(`plotterRemoteVideoTrackAttachment is unset, skipping mounting of ${newPlotterRemoteVideoTrack}`)
                }
            },
            notaryRemoteVideoTrackElem: async function (newNotaryRemoteVideoTrackElem, oldNotaryRemoteVideoTrackElem) {
                logger.info(`Updated notaryRemoteVideoTrackElem from ${oldNotaryRemoteVideoTrackElem?.id} to ${newNotaryRemoteVideoTrackElem?.id}`)

                // Set the loading indicators as necessary
                if (this.notaryRemoteVideoTrackAttachment === this.videoAttachment.largeBodyVideo) {
                    this.largeBodyVideoLoading = true
                } else if (this.notaryRemoteVideoTrackAttachment === this.videoAttachment.smallBodyVideo) {
                    this.smallBodyVideoLoading = true
                }

                // Remove the old Elem from the DOM always, if it exists
                oldNotaryRemoteVideoTrackElem?.remove()

                if (!newNotaryRemoteVideoTrackElem) {
                    return
                }

                assert(this.notaryRemoteVideoTrackAttachment, "You can't set the notaryRemoteVideoTrackElem if your notaryRemoteVideoTrackAttachment is null!")

                // Add the new element to the correct spot in the DOM
                this.notaryRemoteVideoTrackAttachment.appendChild(newNotaryRemoteVideoTrackElem)

                // Set the loaders to false. They are time delayed internally to give the videos a chance to spin up
                if (this.notaryRemoteVideoTrackAttachment === this.videoAttachment.largeBodyVideo) {
                    this.largeBodyVideoLoading = false
                } else if (this.notaryRemoteVideoTrackAttachment === this.videoAttachment.smallBodyVideo) {
                    this.smallBodyVideoLoading = false
                }
            },
            plotterRemoteVideoTrackElem: async function (newPlotterRemoteVideoTrackElem, oldPlotterRemoteVideoTrackElem) {
                logger.info(`Updated plotterRemoteVideoTrackElem from ${inspect(oldPlotterRemoteVideoTrackElem)} to ${inspect(newPlotterRemoteVideoTrackElem)}`)

                // Set the loading indicators as necessary
                if (this.plotterRemoteVideoTrackAttachment === this.videoAttachment.largeBodyVideo) {
                    this.largeBodyVideoLoading = true
                } else if (this.plotterRemoteVideoTrackAttachment === this.videoAttachment.smallBodyVideo) {
                    this.smallBodyVideoLoading = true
                }

                // Remove the old Elem from the DOM always, if it exists
                oldPlotterRemoteVideoTrackElem?.remove()

                if (!newPlotterRemoteVideoTrackElem) {
                    return
                }

                assert(this.plotterRemoteVideoTrackAttachment, "You can't set the plotterRemoteVideoTrackElem if your plotterRemoteVideoTrackAttachment is null!")

                // Add the new element to the correct spot in the DOM
                this.plotterRemoteVideoTrackAttachment.appendChild(newPlotterRemoteVideoTrackElem)

                // Set the loaders to false. They are time delayed internally to give the videos a chance to spin up
                if (this.plotterRemoteVideoTrackAttachment === this.videoAttachment.largeBodyVideo) {
                    this.largeBodyVideoLoading = false
                } else if (this.plotterRemoteVideoTrackAttachment === this.videoAttachment.smallBodyVideo) {
                    this.smallBodyVideoLoading = false
                }
            },
            notaryRemoteVideoTrackAttachment: async function (newRemoteVideoTrackAttachment, priorRemoteVideoTrackAttachment) {
                logger.info(`Updated notaryRemoteVideoTrackAttachment from ${priorRemoteVideoTrackAttachment?.id} to ${newRemoteVideoTrackAttachment?.id}`)

                if (this.notaryRemoteVideoTrackElem) {
                    logger.log(`Unmounting notaryRemoteVideoTrack`)
                    // This is what allows our lovely applicant gets to see the new track
                    this.notaryRemoteVideoTrackElem = null
                }

                // I.e. unmount this video track and leave it alone
                if (!newRemoteVideoTrackAttachment) {
                    logger.log('Refusing to mount notaryRemoteVideoTrack because notaryRemoteVideoTrackAttachment is null')
                    return
                }

                if (this.notaryRemoteVideoTrack) {
                    logger.log(`Reattaching notaryRemoteVideoTrack to DOM @ ${newRemoteVideoTrackAttachment?.id}`)
                    this.notaryRemoteVideoTrackElem = this.notaryRemoteVideoTrack.attach()
                } else {
                    logger.log(`notaryRemoteVideoTrack is unset, skipping remounting`)
                }
            },
            plotterRemoteVideoTrackAttachment: async function (newPlotterRemoteVideoTrackAttachment, priorPlotterRemoteVideoTrackAttachment) {
                logger.info(`Updated plotterRemoteVideoTrackAttachment from ${priorPlotterRemoteVideoTrackAttachment?.id} to ${newPlotterRemoteVideoTrackAttachment?.id}`)

                if (this.plotterRemoteVideoTrackElem) {
                    logger.log(`Unmounting plotterRemoteVideoTrackAttachment`)
                    // This is what allows our lovely applicant gets to see the new track
                    this.plotterRemoteVideoTrackElem = null
                }

                // I.e. unmount this video track and leave it alone
                if (!newPlotterRemoteVideoTrackAttachment) {
                    logger.log('Refusing to mount plotterRemoteVideoTrack because plotterRemoteVideoTrackAttachment is null')
                    return
                }

                if (this.plotterRemoteVideoTrack) {
                    logger.log(`Reattaching plotterRemoteVideoTrack to DOM @ ${newPlotterRemoteVideoTrackAttachment?.id}`)
                    this.plotterRemoteVideoTrackElem = this.plotterRemoteVideoTrack.attach()
                } else {
                    logger.log(`plotterRemoteVideoTrack is unset, skipping remounting`)
                }
            },
            isReadyToStartTwilioRoom: async function (newVal, oldVal) {
                logger.info(`Updated isReadyToStartTwilioRoom from ${oldVal} to ${newVal}`)

                // We ignore false values b/c we use this.twilioRoom = null to explicitly tear down a twilio room
                if (!newVal) {
                    return
                }

                logger.log(`Attempting to join room with accessToken ${this.twilioAccessToken}`)
                assert(this.localVideoTrack, "Can't join a twilio twilioRoom with a null localVideoTrack")
                assert(this.localAudioTrack, "Can't join a twilio twilioRoom with a null localAudioTrack")

                this.twilioRoom = await connect(this.twilioAccessToken, {
                    tracks: [this.localVideoTrack, this.localAudioTrack],
                    networkQuality: {
                        // Maximum verbosity level for both
                        local: 3,
                        remote: 3,
                    },
                    bandwidthProfile: {
                        video: {
                            renderDimensions: {
                                high: { width: 1920, height: 1080 },
                                standard: { width: 1280, height: 720 },
                                low: { width: 640, height: 480 },
                            },
                        },
                    },
                    iceTransportPolicy: 'relay',
                    region: 'us2',
                    // https://github.com/twilio/twilio-video.js/issues/453
                    chromeSpecificConstraints: {
                        mandatory: {
                            googCpuOveruseDetection: true,
                            googCpuOveruseEncodeUsage: true,
                            googCpuOveruseThreshold: 50,
                        },
                    },
                })

                logger.info(`Successfully joined room: ${this.twilioRoom.name}`)
            },
        },
        mounted: async function () {
            this.$logEvent('view_applicant_notary_session')

            // Set these as defaults
            this.localVideoTrackAttachment = this.videoAttachment.smallBodyVideo
            this.notaryRemoteVideoTrackAttachment = this.videoAttachment.largeBodyVideo

            await this.setAvailableAudioVideoDevices()
            this.selectedVideoDeviceId = this.availableVideoDevices[0].deviceId
            this.selectedAudioDeviceId = this.availableAudioDevices[0].deviceId

            this.notaryInfo = await getNotaryInfo()

            logger.log('Attempting to join twilio twilioRoom, gathering info...')

            await this.pollApplicantStatus()

            // First successful applicantStatus response triggers onApplicantStatusSet()
        },
        errorCaptured(error, component, info) {
            logger.warn(`Received error: ${inspect(error)} / from dependent component: ${inspect(component)} / info: ${inspect(info)}`)
            this.errorText = ApiErrorHandler(error)
            // Prevent errors from propagating further up the call stack
            return false
        },
        beforeDestroy() {
            logger.log('Before destroy triggered, running unmounted()')
            this.unmounted()
        },
        methods: {
            // this gets called when applicantStatus is available AND we still want to stay on the page / connect to a notary
            onApplicantStatusSetFirstTime: async function () {
                logger.log('Attempting to join notary session')
                const sessionResponse = await enterApplicantNotarySession()
                if (!sessionResponse.data.success) {
                    // Todo this is actually an unknown error at this point. Eventually we might
                    // want to get more specific on the thanks page.
                    logger.warn(`Couldn't start notarization session from applicant notarization UI: ${inspect(sessionResponse.data)}`)
                    // We have to manually call this in order for our watched handlers to trigger
                    this.unmounted()
                    return router.replace({
                        path: sharedPagePaths.THANKS,
                        query: { reason: MiscThanksReasons.authorization },
                    })
                }

                const payload = sessionResponse.data.payload
                this.applicantNotaryAssignmentId = payload.applicantNotaryAssignmentId

                // This actually starts up the twilio room
                this.twilioAccessToken = payload.applicantAccessToken

                // we don't care about the response. this is just ensuring that pif is available by the time notarization session ends
                // TODO: This should not be in applicant notary code
                fireAndForget(
                    () =>
                        runWithRetryLogic(async () => {
                            return await generatePifInvitesForCardholdersOnLoanApplication()
                        }, 3),
                    (e) => logger.error(`Unable to generate PIF invites for cardholders: ${e}`)
                )
            },
            unmounted: function () {
                logger.log('Unloading page and disconnecting AV tracks')
                this.twilioRoom = null

                // Clear out our polling calls
                window.clearInterval(this.pollApplicantStatusIntervalId)
                window.clearInterval(this.pollTimeRemainingIntervalId)

                logger.log('Attempting to stop all media streams and tracks')
                this.localAudioTrack = null
                this.localVideoTrack = null
            },
            submitSignatureData: async function (signatureSvg) {
                logger.log('Submitting signature for plotter device!')
                await submitPlotterSignature(signatureSvg)
            },
            setAvailableAudioVideoDevices: async function () {
                logger.log('Fetching media devices available on applicant device...')
                try {
                    // Collects all audio and video mediaDevices
                    const mediaDevices = await navigator.mediaDevices.enumerateDevices()
                    logger.log(`Got ${mediaDevices?.length} media devices: ${JSON.stringify(mediaDevices)}`)

                    this.availableVideoDevices = mediaDevices
                        .filter((device) => device.kind === 'videoinput')
                        // Blacklist krisp video devices (expand as necessary)
                        .filter((device) => !/krisp/gi.test(device.label))
                    logger.log(`Got ${this.availableVideoDevices.length} video devices: ${JSON.stringify(this.availableVideoDevices)}`)

                    this.availableAudioDevices = mediaDevices.filter((device) => device.kind === 'audioinput')
                    logger.log(`Got ${this.availableAudioDevices.length} audio devices: ${JSON.stringify(this.availableAudioDevices)}`)
                } catch (e) {
                    logger.warn(`Error enumerating media devices! ${e}`)
                    this.errorText = 'Failed to get access to your video camera or microphone'
                }
            },
            pollApplicantStatus: async function () {
                if (this.pollApplicantStatusIntervalId) {
                    logger.log('Refusing to pollApplicantStatus when pollApplicantStatusIntervalId exists')
                    return
                }

                logger.info('Beginning to poll for applicant status')
                await this.tryGetApplicantStatus()
                this.pollApplicantStatusIntervalId = window.setInterval(this.tryGetApplicantStatus, this.pollApplicantStatusIntervalMsec)
            },
            pollTimeRemaining: async function () {
                if (this.pollTimeRemainingIntervalId) {
                    logger.log('Refusing to pollTimeRemaining when pollTimeRemainingIntervalId exists')
                    return
                }

                logger.info('Beginning to poll for time remaining')
                this.pollApplicantStatusIntervalId = window.setInterval(() => {
                    if (this.sessionStartTime && this.sessionScheduledDurationMin) {
                        const expectedSessionEndTime = DateTime.fromJSDate(this.sessionStartTime).plus({ minute: this.sessionScheduledDurationMin / this.sessionScheduledDurationAdjustmentFactor })
                        this.timeRemainingDuration = expectedSessionEndTime.diffNow()
                    }
                }, 1000)
            },
            tryGetApplicantStatus: async function () {
                try {
                    this.applicantStatus = await getApplicantStatus()
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                }
            },
            playShutterSound: function () {
                // intentionally omitting await
                fireAndForget(
                    () => new Audio(require('@/assets/sounds/camera-shutter-click.mp3')).play(),
                    (error) => logger.info(`[Take picture] Somehow received an error playing the camera shutter sound (probably Safari): ${error}`)
                )
            },
            tryTakeFrontIDPicture: async function () {
                try {
                    logger.log('[Take Front picture] Attempting to take front id picture')

                    this.playShutterSound()

                    let frontB64Image
                    // inspired by: https://stackoverflow.com/questions/62446301/alternative-for-the-imagecapture-api-for-better-browser-support
                    if (window?.ImageCapture) {
                        frontB64Image = await this.takeIDPictureWithImageCaptureApi()
                    } else {
                        frontB64Image = await this.takeIDPictureWithoutImageCaptureApi()
                    }

                    if (!frontB64Image) {
                        throw new Error(`id picture jpegUrl could not be created!`)
                    }

                    logger.log(`[Take Front picture] Successfully retrieved jpeg url: ${frontB64Image}`)

                    this.frontB64Image = frontB64Image
                    this.$logEvent('event_applicant_front_id_image_captured')
                } catch (error) {
                    logger.error(`[Take picture] front ID picture error`, null /* event */, error)
                }
            },
            tryTakeBackIDPicture: async function () {
                try {
                    logger.log('[Take Back picture] Attempting to take back id picture')

                    this.playShutterSound()

                    let backB64Image
                    // inspired by: https://stackoverflow.com/questions/62446301/alternative-for-the-imagecapture-api-for-better-browser-support
                    if (window?.ImageCapture) {
                        backB64Image = await this.takeIDPictureWithImageCaptureApi()
                    } else {
                        backB64Image = await this.takeIDPictureWithoutImageCaptureApi()
                    }

                    if (!backB64Image) {
                        throw new Error(`id picture jpegUrl could not be created!`)
                    }

                    logger.log(`[Take Back picture] Successfully retrieved jpeg url: ${backB64Image}`)

                    logger.log('[Take Back picture] Uploading id picture...')
                    // We purposely do not await this call
                    // b/c there's no further action to be taken regardless of success or failure
                    fireAndForget(
                        () => processCustomerIDDocument(this.frontB64Image, backB64Image),
                        (e) => logger.error(`unable to post 'processCustomerIDDocument! ${e}`)
                    )
                    logger.log('[Take Back picture] id picture uploaded!')
                    this.$logEvent('event_applicant_back_id_image_captured')
                } catch (error) {
                    logger.error(`[Take picture] back ID picture error`, null /* event */, error)
                } finally {
                    this.frontB64Image = null
                }
            },
            takeIDPictureWithImageCaptureApi: async function () {
                logger.log(`[Take picture] Utilizing the ImageCapture API`)
                logger.log(`[Take picture] Instantiating image capture with media stream track: ${JSON.stringify(this.localVideoTrack)}`)
                const imageCapture = new ImageCapture(this.localVideoTrack.mediaStreamTrack)
                logger.log(`[Take picture] Successfully instantiated image capture`)

                let imageBitmap
                try {
                    if (imageCapture.takePhoto) {
                        const imageBlob = await imageCapture.takePhoto()
                        imageBitmap = await createImageBitmap(imageBlob)
                    } else {
                        imageBitmap = await imageCapture.grabFrame()
                    }
                    logger.log(`[Take picture] Got id picture with dimensions ${imageBitmap.width}x${imageBitmap.height}`)
                } catch (error) {
                    logger.error('[Take picture] ImageCapture API (takePhoto or grabFrame) error', null /* event */, error)
                    return
                }

                const canvas = document.createElement('canvas')
                canvas.width = imageBitmap.width
                canvas.height = imageBitmap.height
                canvas.getContext('2d').drawImage(imageBitmap, 0, 0)

                return canvas.toDataURL('image/jpeg')
            },
            takeIDPictureWithoutImageCaptureApi: async function () {
                let video
                let canvas
                let context
                let stream
                try {
                    logger.log(`[Take picture] Unable to utilize the ImageCapture API. Falling back on a secondary methodology.`)
                    video = document.createElement('video')
                    // The next two lines are important for iOS Safari to function correctly
                    video.autoplay = true
                    video.playsInline = true
                    logger.log('[Take picture] Created video element')
                    canvas = document.createElement('canvas')
                    logger.log('[Take picture] Created canvas element')
                    context = canvas.getContext('2d')
                    logger.log('[Take picture] Created context')
                    stream = new MediaStream()
                    logger.log('[Take picture] Created stream')
                    stream.addTrack(this.localVideoTrack.mediaStreamTrack)
                    logger.log('[Take picture] Added track to stream')
                    // Wait for data to load
                    await new Promise((resolve, reject) => {
                        const startWait = Date.now()
                        // Don't wait forever if something is borked
                        const timeoutId = setTimeout(() => reject(new Error('Timeout for picture load, took too long!')), 5 * 1000)
                        video.addEventListener('loadeddata', async () => {
                            clearTimeout(timeoutId)
                            logger.info(`[Take picture] Loaded data in ${Date.now() - startWait} millis`)
                            resolve()
                        })
                        // set up above event listener first before adding the src
                        // this is to avoid the edge case where the event already occurs before we try to listen to it
                        video.srcObject = stream
                        logger.log('[Take picture] Added stream to video element')
                    })
                    canvas.width = video.videoWidth
                    canvas.height = video.videoHeight
                    await video.play()
                    logger.log('[Take picture] Playing video')
                    context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
                    logger.log(`[Take picture] Drawing image with dimensions ${video.videoWidth}x${video.videoHeight}`)
                    return canvas.toDataURL('image/jpeg')
                } catch (error) {
                    logger.error(`[Take picture] Error occurred setting up video element & friends`, null /* event */, error)
                } finally {
                    // clean up
                    logger.log('[Take picture] Cleaning up')
                    canvas?.remove()
                    logger.log('[Take picture] Removed canvas')
                    video?.remove()
                }
            },
        },
    }
</script>
<style lang="scss">
    @import '../../styles/pages/remoteNotarization/applicantSession';
</style>
