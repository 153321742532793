<script>
    import SignaturePad from '@/components/remoteNotarization/VueSignaturePad/signaturePad'
    import { DEFAULT_OPTIONS, TRANSPARENT_PNG } from '@/components/remoteNotarization/VueSignaturePad/utils'
    import _ from 'lodash'
    const ON_SIGNED = 'onSigned'
    const ON_CLEAR = 'onClear'
    export default {
        name: 'VueSignaturePad',
        props: {
            width: {
                type: String,
                default: '100%',
            },
            height: {
                type: String,
                default: '100%',
            },
            customStyle: {
                type: Object,
                default: () => ({}),
            },
            options: {
                type: Object,
            },
            imageType: {
                type: String,
                default: 'svg',
                validator: function (value) {
                    // The value must match one of these strings
                    return ['png', 'jpeg', 'svg'].indexOf(value) !== -1
                },
            },
        },
        data: () => ({
            signaturePad: {},
            signatureData: TRANSPARENT_PNG,
            onResizeHandler: null,
            priorSignatureData: null,
        }),
        computed: {
            isEmpty() {
                return this.signaturePad.isEmpty()
            },
        },
        watch: {
            options: function (nextOptions) {
                Object.keys(nextOptions).forEach((option) => {
                    if (this.signaturePad[option]) {
                        this.signaturePad[option] = nextOptions[option]
                    }
                })
            },
        },
        mounted() {
            const signaturePad = new SignaturePad(this.$refs.signaturePadCanvas, {
                ...DEFAULT_OPTIONS,
                ...this.options,
            })
            this.signaturePad = signaturePad

            this.onResizeHandler = this.resizeCanvas.bind(this)
            window.addEventListener('resize', this.onResizeHandler, false)
            this.resizeCanvasOnRender()
        },
        beforeDestroy() {
            if (this.onResizeHandler) {
                window.removeEventListener('resize', this.onResizeHandler, false)
            }
        },
        methods: {
            resizeCanvas() {
                const canvas = this.signaturePad.canvas
                const data = this.signaturePad.toData()
                const ratio = Math.max(window.devicePixelRatio || 1, 1)
                canvas.width = canvas.offsetWidth * ratio
                canvas.height = canvas.offsetHeight * ratio
                canvas.getContext('2d').scale(ratio, ratio)
                this.signaturePad.clear()
                this.signatureData = TRANSPARENT_PNG
                this.signaturePad.fromData(data)
            },
            off() {
                this.signaturePad.off()
            },
            on() {
                this.signaturePad.on()
            },
            clear() {
                // Remove prior signature data to allow diff algo to reset
                this.priorSignatureData = null
                this.signaturePad.clear() // clear, not undo, to clear entire signature and not just latest stroke
                this.$emit(ON_CLEAR, this.name)
            },
            save() {
                const signature = this.saveSignature()
                this.$emit(ON_SIGNED, { name: this.name, signature: signature.data })
            },
            saveSignature() {
                const { signaturePad } = this
                const status = { isEmpty: false, data: undefined }
                if (signaturePad.isEmpty()) {
                    return {
                        ...status,
                        isEmpty: true,
                    }
                }
                // This only triggers on the first iteration
                // Ergo, there is nothing to 'diff'
                else if (!this.priorSignatureData) {
                    // Save our new signature to an SVG
                    this.signatureData = signaturePad.toDataURL('image/svg+xml')

                    // Update the prior signature data to our current state
                    this.priorSignatureData = signaturePad.toData()

                    return {
                        ...status,
                        data: this.signatureData,
                    }
                }
                // Run the diff-ing algo to generate the partial SVG signature content
                // WARN: The algo /relies/ on the fact that .saveSignature() is called at least once per new stroke
                else {
                    // Save our current signature pad with full signature to a temp var
                    const currentSignatureData = signaturePad.toData()

                    // If the number of strokes is the same, and if the number of points in the last stroke is the same,
                    // then nothing has changed, return our last cached result
                    const currentAndPriorStrokeCountEqual = currentSignatureData.length === this.priorSignatureData.length
                    const currentAndPriorLastStrokePointCountEqual = _.last(currentSignatureData).points.length === _.last(this.priorSignatureData).points.length
                    if (currentAndPriorStrokeCountEqual && currentAndPriorLastStrokePointCountEqual) {
                        return {
                            ...status,
                            data: this.signatureData,
                        }
                    }

                    // Only run this logic if the number of strokes is the same
                    // I.e. diff the points of the last stroke
                    if (currentAndPriorStrokeCountEqual) {
                        // Take all but the last element (stroke) out of the array
                        // Make a copy of it so we're not modifying the original data
                        const lastStroke = _.cloneDeep(_.last(currentSignatureData))

                        // Take all but the most recent 2 points (i.e. 1 segment) out of the stroke
                        // You need to share the last segment to prevent a gap between the new and the prior segments
                        const pointsInPriorSignatureStroke = _.last(this.priorSignatureData).points.length

                        // Take priorPoints - 2 up to a minimum of 0 to prevent getting a negative value
                        // (which changes the behavior of .slice() significantly)
                        // If priorPoints === 1 or 2, then lastStroke.points remains the unchanged (i.e. slice(0))
                        const numberOfPointsToRemoveFromCurrentStroke = pointsInPriorSignatureStroke - 2 > 0 ? pointsInPriorSignatureStroke - 2 : 0
                        console.log(`Found a difference of ${lastStroke.points.length - numberOfPointsToRemoveFromCurrentStroke} points in this stroke`)
                        lastStroke.points = lastStroke.points.slice(numberOfPointsToRemoveFromCurrentStroke)

                        // Load our new 'partial' signature (which are the new points in the last stroke between currentSignaturePadData and priorSignatureData)
                        signaturePad.fromData([lastStroke])
                    }
                    // This logic triggers if we have brand new strokes to look at
                    else {
                        // Get all complete strokes that we missed between priorSignatureData and currentSignatureData
                        const newlyCompleteStrokes = currentSignatureData.slice(this.priorSignatureData.length)
                        // This should only ever be '1' if we're triggering .saveSignature() on every new stroke
                        console.log(`Found a difference of ${newlyCompleteStrokes.length} complete strokes`)

                        // Load our new 'partial' signature (which are the new strokes between currentSignaturePadData and priorSignatureData)
                        signaturePad.fromData(newlyCompleteStrokes)
                    }

                    // Save our partial signature to an SVG
                    this.signatureData = signaturePad.toDataURL('image/svg+xml')

                    // Reset our signature pad with the contents of the full signature
                    signaturePad.fromData(currentSignatureData)

                    // Update the prior signature data to our new state
                    // Copy data to avoid pointer weirdness
                    this.priorSignatureData = _.cloneDeep(currentSignatureData)

                    return {
                        ...status,
                        data: this.signatureData,
                    }
                }
            },
            isRendered() {
                return this.signaturePad.canvas.offsetWidth > 0 && this.signaturePad.canvas.offsetHeight > 0
            },
            resizeCanvasOnRender: async function () {
                if (this.isRendered()) {
                    this.resizeCanvas()
                } else {
                    setTimeout(this.resizeCanvasOnRender, 50)
                }
            },
        },
        render(createElement) {
            const { width, height, customStyle } = this
            return createElement(
                'div',
                {
                    style: {
                        width,
                        height,
                        ...customStyle,
                    },
                },
                [
                    createElement('canvas', {
                        style: {
                            width: '100%',
                            height: '100%',
                        },
                        ref: 'signaturePadCanvas',
                    }),
                ]
            )
        },
    }
</script>
